/* src/components/DataMergePage.css */

.data-merge-container {
    padding: 20px;
    color: white;
    background-color: #1B1E32;
}

.back-button {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
}

.upload-section input[type='file'] {
    background-color: #262C48;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    width: 100%; /* Full width */
}

.upload-section input[type='file']::file-selector-button {
    background-color: #4C5B7C;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.upload-section input[type='file']::file-selector-button:hover {
    background-color: #5C6C8C;
}

.button {
    background-color: #5C6C8C; /* Lighter color */
    color: white;
    border: none;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 12px;
    font-size: 16px;
    width: auto; /* Adjust width to auto */
    text-align: center;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #6C7C9C;
}

/* Center the buttons */
.upload-section,
.merge-button-container {
    display: flex;
    align-items: center;
}

.upload-section .button,
.merge-button-container .button {
    margin-left: 16px; /* Add some space between the input and button */
}

.button:active {
    background-color: #3B4C6C;
}

.schema-selection {
    margin-bottom: 20px;
}

.schema-selection label {
    margin-right: 8px;
}

.schema-selection select {
    background-color: #262C48;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 4px;
    width: 100%; /* Full width */
    margin-top: 8px;
}